<template>
	<nav
		class="navigation"
		:style="computedStyles"
		:class="{
			'navigation--no-logo': !showLogo,
			'navigation--open' : isOpen
		}"
	>
		<div
			v-if="showLogo"
			class="navigation__logo"
			:class="{ 'navigation__logo--m-with-cart': isCartVisible }"
			data-qa="builder-siteheader-img-logo"
		>
			<img
				class="navigation__logo-img"
				:src="logoUrl"
				alt="Logo"
				@click.stop="handleChangePage(website.meta.homepageId)"
			>
		</div>
		<ZyroHamburger
			v-if="itemsIds.length"
			class="navigation__burger"
			:class="{ 'navigation__burger--m-with-cart': isCartVisible }"
			:is-open="isOpen"
			data-qa="builder-siteheader-btn-hamburger"
			@click.stop="isOpen = !isOpen"
		/>
		<ul
			class="navigation__links"
			data-qa="builder-siteheader-emptyspace"
			:class="{ 'navigation__links--open': isOpen }"
		>
			<template v-for="item in navigationItems">
				<BlockNavigationItem
					:key="item.id"
					:item="item"
					:navigation-placement="isMobileMode ? navigationPlacementMobile : navigationPlacement"
					:hide-dropdowns="!isPreviewMode && !isMobileMode"
					are-external-links-disabled
					:is-mobile-view="isMobileMode"
					:navigation-background="data.background"
				>
					<template #link="{ navigationItem, toggleDropdown, classes }">
						<a
							v-qa="`navigationblock-page-${navigationItem.name}`"
							:class="classes"
							@click.stop="handleNavigationItemClick({
								navigationItem,
								toggleDropdown
							})"
							v-text="navigationItem.name"
						/>
					</template>
				</BlockNavigationItem>
			</template>
		</ul>
		<BlockNavigationCartUser
			class="navigation__cart"
			:is-cart-visible="isCartVisible"
			:cart-text="cart.cartText"
			:cart-icon-size="cart.cartIconSize"
			:items-in-cart="ecwidCartItemCount"
			@click="handleCartPageChange"
		/>
	</nav>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import ZyroHamburger from '@/components/ui/ZyroHamburger.vue';
/*
 * Need to use userConstants since this navigation block is aso included to user bundle
 * (I think this is because preview mode uses it)
 */
import {
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_LINK_TYPE,
	NAVIGATION_FOLDER_TYPE,
	NAVIGATION_LOGO_SPACING_MAP,
} from '@/use/navigation/userConstants';
import BlockNavigationCartUser from '@user/components/block-navigation/BlockNavigationCartUser.vue';
import { useEcwidStore } from '@user/useStore/useEcwidStore';

import BlockNavigationItem from './block-navigation-item/BlockNavigationItem.vue';
import { logoMenuPositionMap } from './logoMenuPositionMap';

export default {
	NAVIGATION_PAGE_TYPE,
	components: {
		BlockNavigationCartUser,
		ZyroHamburger,
		BlockNavigationItem,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const {
			ecwidCartItemCount,
			openCart,
		} = useEcwidStore();

		return {
			ecwidCartItemCount,
			openCart,
		};
	},
	data() {
		return { isOpen: false };
	},
	computed: {
		...mapState(['website']),
		...mapState('gui', [
			'isMobileView',
			'isPreviewMode',
		]),
		...mapState('pages', ['currentPageId']),
		...mapGetters('pages', [
			'defaultPages',
			'currentPage',
			'ecommercePages',
		]),
		...mapGetters('navigation', [
			'items',
			'itemsIds',
		]),
		...mapGetters('onlineStore', ['isSiteWithEcwid']),
		...mapGetters('gui', ['isMobileMode']),
		logoUrl() {
			return this.data.settings.logoUrl;
		},
		showLogo() {
			return this.data.settings.showLogo;
		},
		cart: ({ data }) => ({
			isCartVisible: data.settings.isCartVisible,
			cartText: data.settings.cartText,
			cartIconSize: data.settings.styles.cartIconSize,
		}),
		navigationItems() {
			const mapItem = (itemId) => {
				const item = this.items[itemId];
				const {
					name,
					type,
				} = item;
				const isPage = type === NAVIGATION_PAGE_TYPE;
				const isLink = type === NAVIGATION_LINK_TYPE;
				const isFolder = type === NAVIGATION_FOLDER_TYPE;

				return {
					...item,
					name: isPage ? this.defaultPages[itemId].name : name,
					id: itemId,
					isPage,
					isLink,
					isFolder,
					// Hide dropdown menus in desktop editor mode
					hasDropdown: item.subItems.length > 0 || item.isFolder,
					isCurrent: itemId === this.currentPageId,
					subItems: item.subItems.map((subItemId) => mapItem(subItemId)),
				};
			};

			return this.itemsIds.map(mapItem);
		},
		isCartVisible() {
			return this.cart.isCartVisible && this.isSiteWithEcwid;
		},
		logoPlacement() {
			return this.data.settings.logoPlacement;
		},
		logoPlacementMobile() {
			return this.data.settings['m-logoPlacement'] || this.data.settings.logoPlacement;
		},
		navigationPlacement() {
			return this.data.settings.navigationPlacement;
		},
		navigationPlacementMobile() {
			return this.data.settings['m-navigationPlacement'] || this.data.settings.navigationPlacement;
		},
		isLogoAndNavigationCentered() {
			return this.logoPlacement === 'center' && this.navigationPlacement === 'center';
		},
		isLogoAndNavigationCenteredMobile() {
			return this.logoPlacementMobile === 'center' && this.navigationPlacementMobile === 'center';
		},
		navigationGridRowCount() {
			if (this.showLogo && this.isCartVisible) {
				return 3;
			}

			return 2;
		},
		isTransparent() {
			return this.data.background.isTransparent;
		},
		computedStyles() {
			const placementKey = `${this.logoPlacement}-${this.navigationPlacement}`;
			const placementKeyMobile = `${this.logoPlacementMobile}-${this.navigationPlacementMobile}`;

			const { templateColumns } = logoMenuPositionMap[placementKey];
			const templateColumnsMobile = this.isCartVisible
				? 'auto minmax(0, calc(var(--m-logo-width) + var(--space-between-menu) * 2)) 1fr'
				: logoMenuPositionMap[placementKeyMobile].mTemplateColumns;

			const hamburgerMenuPositionTop = !this.isCartVisible && placementKeyMobile !== 'center-center'
				? 'calc(var(--m-padding-top, var(--padding-top)) - 1px)'
				: 'calc(100% + var(--m-padding-top, var(--padding-top)))';

			return {
				'--navigation-grid-template-columns': this.showLogo ? templateColumns : '1fr',
				'--m-navigation-grid-template-columns': this.showLogo ? templateColumnsMobile : '1fr',

				'--logo-grid-row': `${logoMenuPositionMap[placementKey].logoRow}/${this.navigationGridRowCount}`,
				'--m-logo-grid-row': `${logoMenuPositionMap[placementKeyMobile].logoRow}/${this.navigationGridRowCount}`,

				'--logo-grid-column': logoMenuPositionMap[placementKey].logoColumn,
				'--m-logo-grid-column': logoMenuPositionMap[placementKeyMobile].logoColumn,

				'--logo-justify-self': this.getJustifyContent(this.logoPlacement),
				'--m-logo-justify-self': this.getJustifyContent(this.logoPlacementMobile),

				'--logo-image-object-position': this.getLogoImageObjectPosition(this.logoPlacement),
				'--m-logo-image-object-position': this.getLogoImageObjectPosition(this.logoPlacementMobile),

				'--links-grid-row': `${logoMenuPositionMap[placementKey].menuRow}/${this.navigationGridRowCount}`,
				'--m-links-grid-row': `${logoMenuPositionMap[placementKeyMobile].menuRow}/${this.navigationGridRowCount}`,

				'--links-grid-column': this.showLogo ? logoMenuPositionMap[placementKey].menuColumn : '1/2',
				'--m-links-grid-column': this.showLogo ? logoMenuPositionMap[placementKeyMobile].menuColumn : '1/2',

				'--cart-grid-row': `${logoMenuPositionMap[placementKey].cartRow}/${this.navigationGridRowCount}`,
				'--cart-grid-column': this.showLogo ? logoMenuPositionMap[placementKey].cartColumn : '2/2',

				'--logo-spacing': this.getLogoMarginPlacement(),
				'--m-logo-spacing': this.getLogoMarginPlacement(true),

				'--navigation-text-align': this.navigationPlacement,
				'--m-navigation-text-align': this.navigationPlacementMobile,

				'--navigation-justify-self': this.getJustifyContent(this.navigationPlacement),
				'--m-navigation-justify-self': this.getJustifyContent(this.navigationPlacementMobile),

				'--hamburgerMenuPositionTop': hamburgerMenuPositionTop,
			};
		},
	},
	watch: {
		isMobileView(value) {
			if (!value) {
				this.isOpen = value;
			}
		},
	},
	methods: {
		...mapActions('pages', ['changePage']),
		handleChangePage(itemId) {
			if (window.Ecwid) {
				window.Ecwid.openPage('category');
			}

			this.changePage({ pageId: itemId });
			this.isOpen = false;
		},
		handleCartPageChange() {
			const firstEcommercePageId = Object.keys(this.ecommercePages)[0];

			this.handleChangePage(firstEcommercePageId);

			if (window.Ecwid) {
				this.openCart();
			}
		},
		handleNavigationItemClick({
			navigationItem,
			toggleDropdown,
		}) {
			if (navigationItem.isPage) {
				this.handleChangePage(navigationItem.id);
			} else if (navigationItem.isFolder && this.isMobileView) {
				toggleDropdown();
			}
		},
		getJustifyContent(align) {
			switch (align) {
			case 'left': return 'flex-start';
			case 'right': return 'flex-end';
			case 'center': return 'center';
			default: return 'center';
			}
		},
		getLogoImageObjectPosition(align) {
			switch (align) {
			case 'left': return 'left center';
			case 'right': return 'right center';
			case 'center': return 'center center';
			default: return 'left center';
			}
		},
		getLogoMarginPlacement(isMobile = false) {
			const isLogoAndNavigationCentered = isMobile ? this.isLogoAndNavigationCenteredMobile : this.isLogoAndNavigationCentered;
			const logoPlacement = isMobile ? this.logoPlacementMobile : this.logoPlacement;

			if (isMobile && this.isCartVisible) {
				return NAVIGATION_LOGO_SPACING_MAP.center;
			}

			if (isLogoAndNavigationCentered) {
				return NAVIGATION_LOGO_SPACING_MAP['center-center'];
			}

			return NAVIGATION_LOGO_SPACING_MAP[logoPlacement];
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@user/components/block-navigation/BlockNavigation.scss';
</style>
