<template>
	<li
		class="block-navigation-item"
		:style="computedStyles"
	>
		<ItemContent
			:item="item"
			class="block-navigation-item__item"
			:aria-haspopup="item.hasDropdown"
			:aria-expanded="isDropdownShown"
			:is-dropdown-shown="isDropdownShown"
			:is-dropdown-item="isDropdownItem"
			:are-external-links-disabled="areExternalLinksDisabled"
			:navigation-placement="navigationPlacement"
			:is-mobile-view="isMobileView"
			:hide-dropdowns="hideDropdowns"
			@toggle-dropdown="toggleDropdown"
			@mouseover.native="showOnDesktop"
			@mouseleave.native="hideOnDesktop"
		>
			<template #link="slotProps">
				<slot
					name="link"
					v-bind="slotProps"
				/>
			</template>
		</ItemContent>
		<Transition name="slide-top-to-bottom">
			<div
				v-if="!hideDropdowns && item.hasDropdown"
				v-show="isDropdownShown"
				class="block-navigation-item__dropdown-area"
				@mouseover="showOnDesktop"
				@mouseleave="hideOnDesktop"
			>
				<ul
					:class="[
						'block-navigation-item__dropdown',
						`block-navigation-item__dropdown--align-${navigationPlacement}`
					]"
				>
					<BlockNavigationItem
						v-for="subItem in item.subItems"
						:key="subItem.id"
						:item="subItem"
						:is-dropdown-item="true"
						:are-external-links-disabled="areExternalLinksDisabled"
						:is-mobile-view="isMobileView"
						v-on="$listeners"
					>
						<!--
							Pass scoped slots down to the subitems list
							(https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2)
						-->
						<template
							v-for="(_, name) in $scopedSlots"
							:slot="name"
							slot-scope="slotData"
						>
							<slot
								:name="name"
								v-bind="slotData"
							/>
						</template>
					</BlockNavigationItem>
				</ul>
			</div>
		</Transition>
	</li>
</template>

<script>
import ItemContent from './ItemContent.vue';

export default {
	name: 'BlockNavigationItem',
	components: { ItemContent },
	props: {
		item: {
			type: Object,
			required: true,
		},
		isDropdownItem: {
			type: Boolean,
			default: false,
		},
		navigationBackground: {
			type: Object,
			default: () => ({}),
		},
		navigationPlacement: {
			type: String,
			default: 'center',
		},
		hideDropdowns: {
			type: Boolean,
			default: false,
		},
		areExternalLinksDisabled: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { isDropdownShown: false };
	},
	computed: {
		computedStyles() {
			return {
				'--dropdown-background-color': this.navigationBackground.color,
				'--m-dropdown-background-color': this.navigationBackground.current === 'image' ? 'transparent' : 'var(--contrastBackgroundColor)',
			};
		},
	},
	watch: {
		isMobileView() {
			// Close dropdowns when changing from mobile view to desktop
			if (!this.isMobileView) {
				this.isDropdownShown = false;
			}
		},
	},
	methods: {
		toggleDropdown() {
			this.isDropdownShown = !this.isDropdownShown;
		},
		showOnDesktop() {
			this.toggleDropdownVisibilityOnDesktop(true);
		},
		hideOnDesktop() {
			this.toggleDropdownVisibilityOnDesktop(false);
		},
		toggleDropdownVisibilityOnDesktop(value) {
			if (!this.isMobileView) {
				this.isDropdownShown = value;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.block-navigation-item {
	$this: &;

	&__dropdown-area {
		$side-padding: 16px;

		position: absolute;
		z-index: 1;
		padding-top: 24px;
		padding-right: $side-padding;
		padding-bottom: 48px;
		padding-left: $side-padding;
		margin-left: calc(var(--menu-item-spacing) - #{$side-padding});
	}

	&__dropdown {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 16px 24px;
		list-style: none;
		background-color: var(--dropdown-background-color);
		border-radius: $border-radius-small;
		box-shadow: $box-shadow;

		& > #{$this}:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}

@include zyro-media($media-grid) {
	.block-navigation-item {
		$this: &;
		$subItemIndentation: 16px;

		&__dropdown-area {
			position: unset;
			padding: 8px 0;
			margin: 8px calc(-1 * var(--padding-left)) 0 calc(-1 * var(--padding-right));
			background-color: var(--m-dropdown-background-color);
		}

		&__dropdown {
			padding: 0;
			background-color: transparent;
			box-shadow: none;

			&--align-left {
				align-items: flex-start;
				padding-left: calc(var(--padding-right) + #{$subItemIndentation});
			}

			&--align-center {
				align-items: center;
			}

			&--align-right {
				align-items: flex-end;
				padding-right: calc(var(--padding-right) + #{$subItemIndentation});
			}
		}
	}
}
</style>
