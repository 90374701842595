<template>
	<ZyroTooltip
		size="x-large"
		toggle-event="none"
		has-close-icon
		:hide-tooltip="hideTooltip"
		:forced-position="forcedTooltipPosition"
		@on-close="onTooltipClose"
	>
		<div class="tooltip">
			<ZyroPill
				class="tooltip__pill"
				:text="$t('builder.previewRibbonTooltipPill')"
				:text-to-uppercase="false"
			/>
			<h5 class="z-body-small z-font-weight-bold">
				{{ $t('builder.previewRibbonTooltipHeadline') }}
			</h5>
			<p class="z-body-small">
				{{ $t('builder.previewRibbonTooltipText') }}
				{{ lowestBasicPlanPrice }}/{{ $t('common.perMonth') }}
			</p>
			<ZyroButton
				theme="primary"
				class="tooltip__button"
				data-qa="builder-preview-btn-gopremium"
				@click="handleTooltipClick"
			>
				{{ $t('builder.previewRibbonTooltipCta') }}
			</ZyroButton>
		</div>
	</ZyroTooltip>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import i18n from '@/i18n/setup';
import { useRedirects } from '@/use/useRedirects';

const TOOLTIP_DELAY = 2000;

export const MOBILE_TOOLTIP_POSITION = {
	left: 'calc(50% - 188px)', // 188 = 379 (preview device width) - 2 (borders) / 2
	top: '222px',
};
export const DESKTOP_TOOLTIP_POSITION = {
	left: '13%',
	top: '105px',
};
export const TOOLTIP_CLOSE_EVENT = { eventName: 'site.ribbonTooltip(upgrade&save).close' };
export const TOOLTIP_CLICK_EVENT = {
	eventName: 'site.ribbonTooltip(upgrade&save).click-cta',
	eventProperties: { label: i18n.t('builder.previewRibbonTooltipHeadline') },
};

export default {
	MOBILE_TOOLTIP_POSITION,
	DESKTOP_TOOLTIP_POSITION,
	setup() {
		const { redirectToWWWPayments } = useRedirects();

		return { redirectToWWWPayments };
	},
	data() {
		return {
			hideTooltip: true,
			tooltipTimer: true,
		};
	},
	computed: {
		...mapState('gui', ['isMobileView']),
		...mapState('user', ['user']),
		...mapGetters('subscription', ['lowestBasicPlanPrice']),
		forcedTooltipPosition({ isMobileView }) {
			return isMobileView ? MOBILE_TOOLTIP_POSITION : DESKTOP_TOOLTIP_POSITION;
		},
	},
	created() {
		this.tooltipTimer = setTimeout(() => {
			this.hideTooltip = false;
			EventLogApi.logEvent({ eventName: 'site.ribbonTooltip(upgrade&save).loaded' });
		}, TOOLTIP_DELAY);
	},
	beforeDestroy() {
		clearTimeout(this.tooltipTimer);
	},
	methods: {
		handleTooltipClick() {
			EventLogApi.logEvent(TOOLTIP_CLICK_EVENT);
			this.redirectToWWWPayments({ discount: true });
		},
		onTooltipClose() {
			EventLogApi.logEvent(TOOLTIP_CLOSE_EVENT);
		},
	},
};
</script>

<style lang="scss" scoped>

.tooltip {
	max-width: 256px;

	&__pill {
		display: inline-flex;
		margin-bottom: 10px;
		color: $light;
		text-transform: none;
		background-color: $secondary;
	}

	&__button {
		margin-top: 8px;
	}
}
</style>
