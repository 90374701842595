<template>
	<Transition
		name="fade"
		appear
	>
		<div
			v-if="isLightboxOpen"
			class="lightbox"
			role="dialog"
			aria-modal="true"
			:style="computedStyles"
		>
			<LightboxCloseButton
				class="lightbox__button lightbox__close-button"
				:class="{ 'lightbox__close-button--preview-mode-desktop': isPreviewMode && !isMobileView }"
				title="close-ligthbox-button"
				@close-click="closeLightbox"
			/>
			<div class="lightbox__container">
				<div
					v-if="isNavigationVisible"
					class="lightbox__control-container"
					@click="goToPreviousImage"
				>
					<LightboxNavButton
						class="lightbox__button"
						title="navigation-left-button"
					/>
				</div>
				<Transition
					name="fade"
					mode="out-in"
				>
					<img
						:key="`${currentLightboxImage.src}${currentLightboxImageIndex}`"
						ref="lightboxRef"
						v-click-outside="{
							handler: closeLightbox,
							middleware: onLightboxClickOutside,
							events: ['mousedown'],
						}"
						class="lightbox__image"
						:class="{
							'lightbox__image--single': !isNavigationVisible,
							'lightbox__image--preview-mobile': isMobileView && isImageGaleryMode,
						}"
						:draggable="isMobileView ? 'true' : 'false'"
						:src="currentLightboxImage.src"
						:alt="currentLightboxImage.alt"
					>
				</Transition>
				<div
					v-if="isNavigationVisible"
					class="lightbox__control-container"
					@click="goToNextImage"
				>
					<LightboxNavButton
						class="lightbox__button lightbox__control-button--right"
						title="navigation-right-button"
					/>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import {
	onBeforeUnmount,
	computed,
	onMounted,
} from '@vue/composition-api';
import {
	disableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import LightboxCloseButton from '@/components/reusable-components/lightbox/LightboxCloseButton.vue';
import LightboxNavButton from '@/components/reusable-components/lightbox/LightboxNavButton.vue';
import { useLightbox } from '@/components/reusable-components/lightbox/useLightbox';
import { moveDirection } from '@/utils/moveDirection';

export default {
	name: 'Lightbox',
	components: {
		LightboxCloseButton,
		LightboxNavButton,
	},
	props: {
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			required: true,
		},
	},
	setup(props) {
		const {
			lightboxImages,
			currentLightboxImageIndex,
			currentLightboxImage,
			closeLightbox,
			isLightboxOpen,
			isFirstImage,
			isLastImage,
			isImageGaleryMode,
			onLightboxClickOutside,
			handleLightboxNav,
			goToNextImage,
			goToPreviousImage,
		} = useLightbox();

		const { enableMoveEvents } = moveDirection({
			move: props.isPreviewMode ? {
				drag: true,
				swipe: true,
			} : {
				swipe: true,
				drag: false,
			},
			onMoveLeft: goToNextImage,
			onMoveRight: goToPreviousImage,
		});

		const computedStyles = computed(() => ({ '--backdrop-position': props.isPreviewMode && props.isMobileView ? 'absolute' : 'fixed' }));

		const isNavigationVisible = computed(() => !props.isMobileView && isImageGaleryMode.value);

		onMounted(() => {
			enableMoveEvents();
			window.document.addEventListener('keydown', handleLightboxNav);
		});

		onBeforeUnmount(() => {
			closeLightbox();
			enableMoveEvents(false);
			document.removeEventListener('keydown', handleLightboxNav);
		});

		return {
			lightboxImages,
			currentLightboxImageIndex,
			currentLightboxImage,
			closeLightbox,
			isImageGaleryMode,
			isNavigationVisible,
			isFirstImage,
			isLastImage,
			isLightboxOpen,
			onLightboxClickOutside,
			goToNextImage,
			goToPreviousImage,
			computedStyles,
		};
	},
	async mounted() {
		await this.$nextTick();
		disableBodyScroll(this.$refs.lightboxRef);
	},
	beforeDestroy() {
		clearAllBodyScrollLocks();
	},
};
</script>

<style scoped lang="scss">
$button-hover-background: rgba($light, 0.2);

.lightbox {
	$this: &;

	position: var(--backdrop-position);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: z-index(user--overlay);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($dark, 0.8);

	&__button {
		display: flex;
		padding: 16px 19px;
		color: $grey-200;
		cursor: pointer;
		background-color: transparent;
		transition: background-color ease 0.2s;

		&:hover,
		&:focus-visible {
			background-color: $button-hover-background;
		}
	}

	&__container {
		display: flex;
		width: 100%;
		max-height: 721px;
	}

	&__control-container {
		display: flex;
		flex: 0 1 10%;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover,
		&:focus-visible {
			#{$this}__button {
				background-color: $button-hover-background;
			}
		}
	}

	&__close-button {
		position: absolute;
		top: 0;
		right: 0;

		&--preview-mode-desktop {
			top: $header-height;
		}
	}

	&__control-button {
		&--right {
			transform: rotate(180deg);
		}
	}

	&__image {
		position: relative;
		flex: 0 0 80%;
		max-width: 80%;
		max-height: 721px;
		padding: 0;
		margin: auto;
		text-align: center;
		object-fit: contain;

		&--single {
			flex: none;
			max-width: 100%;
		}

		&--preview-mobile {
			cursor: grab;
		}

		&--preview-mobile:active {
			cursor: grabbing;
		}
	}

	@media screen and (max-width: 746px) {
		#{$this}__control-container {
			display: none;
		}

		#{$this}__image {
			max-width: 100%;
		}
	}
}
</style>
