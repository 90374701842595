var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation",class:{
		'navigation--no-logo': !_vm.showLogo,
		'navigation--open' : _vm.isOpen
	},style:(_vm.computedStyles)},[(_vm.showLogo)?_c('div',{staticClass:"navigation__logo",class:{ 'navigation__logo--m-with-cart': _vm.isCartVisible },attrs:{"data-qa":"builder-siteheader-img-logo"}},[_c('img',{staticClass:"navigation__logo-img",attrs:{"src":_vm.logoUrl,"alt":"Logo"},on:{"click":function($event){$event.stopPropagation();return _vm.handleChangePage(_vm.website.meta.homepageId)}}})]):_vm._e(),(_vm.itemsIds.length)?_c('ZyroHamburger',{staticClass:"navigation__burger",class:{ 'navigation__burger--m-with-cart': _vm.isCartVisible },attrs:{"is-open":_vm.isOpen,"data-qa":"builder-siteheader-btn-hamburger"},on:{"click":function($event){$event.stopPropagation();_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('ul',{staticClass:"navigation__links",class:{ 'navigation__links--open': _vm.isOpen },attrs:{"data-qa":"builder-siteheader-emptyspace"}},[_vm._l((_vm.navigationItems),function(item){return [_c('BlockNavigationItem',{key:item.id,attrs:{"item":item,"navigation-placement":_vm.isMobileMode ? _vm.navigationPlacementMobile : _vm.navigationPlacement,"hide-dropdowns":!_vm.isPreviewMode && !_vm.isMobileMode,"are-external-links-disabled":"","is-mobile-view":_vm.isMobileMode,"navigation-background":_vm.data.background},scopedSlots:_vm._u([{key:"link",fn:function(ref){
	var navigationItem = ref.navigationItem;
	var toggleDropdown = ref.toggleDropdown;
	var classes = ref.classes;
return [_c('a',{directives:[{name:"qa",rawName:"v-qa",value:(("navigationblock-page-" + (navigationItem.name))),expression:"`navigationblock-page-${navigationItem.name}`"}],class:classes,domProps:{"textContent":_vm._s(navigationItem.name)},on:{"click":function($event){$event.stopPropagation();return _vm.handleNavigationItemClick({
							navigationItem: navigationItem,
							toggleDropdown: toggleDropdown
						})}}})]}}],null,true)})]})],2),_c('BlockNavigationCartUser',{staticClass:"navigation__cart",attrs:{"is-cart-visible":_vm.isCartVisible,"cart-text":_vm.cart.cartText,"cart-icon-size":_vm.cart.cartIconSize,"items-in-cart":_vm.ecwidCartItemCount},on:{"click":_vm.handleCartPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }