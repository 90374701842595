<template>
	<div
		class="builder-mobile-frame"
		:class="{
			'zyro-mb-preview': isMobileView,
			'zyro-desktop-preview': !(isMobileView || isMobileScreen),
			'zyro-mb-preview--framed': showMockup && isMobileView
		}"
		:style="computedStyles"
	>
		<div
			ref="mobileFrame"
			class="builder-mobile-frame__preview"
		>
			<slot />
		</div>
		<BuilderRibbonTooltip v-if="showTooltip" />
		<Lightbox
			v-if="isLightboxOpen"
			is-preview-mode
			:is-mobile-view="isMobileView"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import BuilderRibbonTooltip from '@/components/builder-view/components/BuilderRibbonTooltip.vue';
import Lightbox from '@/components/reusable-components/lightbox/Lightbox.vue';
import { useLightbox } from '@/components/reusable-components/lightbox/useLightbox';
import { PREVIEW_ROUTE } from '@/router';

export default {
	components: {
		Lightbox,
		BuilderRibbonTooltip,
	},
	props: {
		showMockup: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const { isLightboxOpen } = useLightbox();

		return { isLightboxOpen };
	},
	computed: {
		...mapState(['hasActivePlan']),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		...mapState('pages', ['currentPageId']),
		...mapState('user', ['isUserDesigner']),
		showTooltip({
			hasActivePlan,
			isUserDesigner,
		}) {
			return !hasActivePlan && !isUserDesigner && this.$route.name === PREVIEW_ROUTE;
		},
		computedStyles() {
			return { '--scroll-y': this.isLightboxOpen && this.isMobileView ? 'hidden' : 'scroll' };
		},
	},
	watch: {
		currentPageId() {
			this.$refs.mobileFrame.scrollTop = 0;
		},
	},
};
</script>

<style lang="scss" scoped>
.builder-mobile-frame {
	$this: &;

	width: 100%;
	height: 100%;
	margin: auto;
	transition: width 500ms, margin 500ms, border 500ms, height 500ms;

	&::before,
	&::after {
		position: absolute;
		width: 0;
		height: 0;
		content: '';
		transition: width 500ms, height 500ms;
	}

	&.zyro-mb-preview {
		$border-width: 2px;
		$iPhone-frame: 80px;
		$iPhone-screen-width: 375px;

		width: ($iPhone-screen-width + $border-width * 2);
		margin: auto;

		&--framed {
			position: relative;
			width: ($iPhone-screen-width + $border-width * 2);
			height: $iPhone-screen-height + $iPhone-frame + $iPhone-frame;
			border: $border-width solid black;
			border-top-width: $iPhone-frame;
			border-bottom-width: $iPhone-frame;
			border-radius: 50px;
			box-shadow: 6px 8px 14px rgba(0, 0, 0, 0.5);

			#{$this}__preview {
				max-height: $iPhone-screen-height; // iPhone 5s height
				overflow-x: hidden;
				overflow-y: var(--scroll-y);
			}

			&::before {
				top: -40px;
				left: 25%;
				z-index: 1;
				background-color: gray;
				border-radius: 50%;
				transform: translateX(-50%);
				animation-name: zoom-in-top-button;
				animation-duration: 300ms;
				animation-delay: 500ms;
				animation-fill-mode: forwards;
			}

			&::after {
				bottom: -40px;
				left: 50%;
				z-index: 1;
				background-color: gray;
				border-radius: 50%;
				transform: translateX(-50%);
				animation-name: zoom-in-bottom-button;
				animation-duration: 300ms;
				animation-delay: 500ms;
				animation-fill-mode: forwards;
			}
		}
	}

	&__preview {
		position: relative;
	}
}

@keyframes zoom-in-bottom-button {
	from {
		bottom: -40px;
		width: 0;
		height: 0;
	}

	to {
		bottom: -62px;
		width: 46px;
		height: 46px;
	}
}

@keyframes zoom-in-top-button {
	from {
		top: -40px;
		width: 0;
		height: 0;
	}

	to {
		top: -45px;
		width: 10px;
		height: 10px;
	}
}
</style>
