var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Transition',{attrs:{"name":"fade","appear":""}},[(_vm.isLightboxOpen)?_c('div',{staticClass:"lightbox",style:(_vm.computedStyles),attrs:{"role":"dialog","aria-modal":"true"}},[_c('LightboxCloseButton',{staticClass:"lightbox__button lightbox__close-button",class:{ 'lightbox__close-button--preview-mode-desktop': _vm.isPreviewMode && !_vm.isMobileView },attrs:{"title":"close-ligthbox-button"},on:{"close-click":_vm.closeLightbox}}),_c('div',{staticClass:"lightbox__container"},[(_vm.isNavigationVisible)?_c('div',{staticClass:"lightbox__control-container",on:{"click":_vm.goToPreviousImage}},[_c('LightboxNavButton',{staticClass:"lightbox__button",attrs:{"title":"navigation-left-button"}})],1):_vm._e(),_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('img',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
						handler: _vm.closeLightbox,
						middleware: _vm.onLightboxClickOutside,
						events: ['mousedown'],
					}),expression:"{\n\t\t\t\t\t\thandler: closeLightbox,\n\t\t\t\t\t\tmiddleware: onLightboxClickOutside,\n\t\t\t\t\t\tevents: ['mousedown'],\n\t\t\t\t\t}"}],key:("" + (_vm.currentLightboxImage.src) + _vm.currentLightboxImageIndex),ref:"lightboxRef",staticClass:"lightbox__image",class:{
						'lightbox__image--single': !_vm.isNavigationVisible,
						'lightbox__image--preview-mobile': _vm.isMobileView && _vm.isImageGaleryMode,
					},attrs:{"draggable":_vm.isMobileView ? 'true' : 'false',"src":_vm.currentLightboxImage.src,"alt":_vm.currentLightboxImage.alt}})]),(_vm.isNavigationVisible)?_c('div',{staticClass:"lightbox__control-container",on:{"click":_vm.goToNextImage}},[_c('LightboxNavButton',{staticClass:"lightbox__button lightbox__control-button--right",attrs:{"title":"navigation-right-button"}})],1):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }