<template>
	<div
		:class="[
			'item-content-wrapper',
			`item-content-wrapper--align-${navigationPlacement}`,
			{ 'item-content-wrapper--active': isActive },
		]"
	>
		<!--
			- classes slotted prop is passed so that state classes could be applied directly to slot
			content instead of its wrapper
			- toggleDropdwn method is exposed so that slot content could trigger the BlockNavigationItem
			component dropdown
		-->
		<slot
			name="link"
			:navigation-item="item"
			:classes="[
				'item-content',
				...computedClasses
			]"
			:toggle-dropdown="toggleDropdown"
		/>
		<div
			v-if="item.hasDropdown"
			class="item-content__icon-container-wrapper"
		>
			<div
				class="item-content__icon-container"
				:class="{ 'item-content__icon-container--bordered' : isIconBordered }"
				role="button"
				tabindex="0"
				:aria-pressed="isDropdownShown"
				@click="isMobileView && toggleDropdown()"
				@keyup.enter="toggleDropdown"
			>
				<svg
					class="item-content__icon"
					:class="{ 'item-content__icon--rotated' : !hideDropdowns && isDropdownShown }"
					width="10"
					height="7"
					viewBox="0 0 10 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5 6.5L0.669873 0.5L9.33013 0.500001L5 6.5Z"
						fill="currentColor"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		isDropdownShown: {
			type: Boolean,
			required: true,
		},
		navigationPlacement: {
			type: String,
			required: true,
		},
		isDropdownItem: {
			type: Boolean,
			default: false,
		},
		hideDropdowns: {
			type: Boolean,
			default: false,
		},
		areExternalLinksDisabled: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isActive: ({ item }) => item.isPage && item.isCurrent,
		isIconBordered: ({
			item,
			isMobileView,
			isDropdownShown,
		}) => (
			item.isPage && isMobileView && isDropdownShown
		),
		computedClasses: ({
			isActive,
			item,
			isDropdownItem,
			areExternalLinksDisabled,
		}) => ({
			'item-content--is-active': isActive,
			'item-content--external-link-disabled': areExternalLinksDisabled && item.isLink,
			'item-content--is-dropdown-item': isDropdownItem,
		}),
	},
	methods: {
		toggleDropdown() {
			this.$emit('toggle-dropdown');
		},
	},
};
</script>

<style lang="scss" scoped>
@include font-style('nav-link', 'item-content');

$underline-space: 7px;

.item-content {
	position: relative;
	align-self: center;
	margin: 0 0 0 var(--menu-item-spacing);
	color: var(--nav-link-color);
	text-decoration: none;
	white-space: pre-wrap;
	cursor: pointer;

	&__icon-container {
		$icon-container: &;

		display: flex;
		margin-left: 8px;
		color: var(--nav-link-color);
		cursor: pointer;
		border: 1px solid transparent;
		border-radius: 3px;
		transition: border 0.3s;

		&:focus-visible {
			border-color: var(--nav-link-color-hover, var(--nav-link-color));
		}

		&--bordered {
			border-color: var(--nav-link-color);
		}
	}

	&__icon {
		margin: 2px 6.5px;
		transition: transform 0.3s;

		&--rotated {
			transform: rotate(180deg);
		}
	}

	&--external-link-disabled {
		cursor: not-allowed;
	}

	&--is-dropdown-item {
		margin: 0;
	}
}

.item-content-wrapper {
	display: flex;
	align-items: center;
	text-align: left;

	&--active,
	&:hover,
	&:focus,
	&.router-link-exact-active {
		.item-content {
			color: var(--nav-link-color-hover, var(--nav-link-color));
			text-decoration: underline;
			text-underline-offset: $underline-space;

			&__icon-container {
				color: var(--nav-link-color-hover, var(--nav-link-color));

				&--bordered {
					border-color: var(--nav-link-color-hover, var(--nav-link-color));
				}
			}
		}
	}
}

@include zyro-media($media-grid) {
	.item-content {
		margin: 0 0 $underline-space 0;

		&__icon-container-wrapper {
			align-self: start;
		}

		&__icon-container {
			display: inline-block;
		}
	}

	.item-content-wrapper {
		display: grid;

		&--align-left {
			grid-template-columns: auto 1fr auto;

			& > .item-content {
				grid-column: 1/2;
			}

			& > .item-content__icon-container-wrapper {
				grid-column: 2/3;
				align-self: start;
			}
		}

		&--align-center {
			grid-template-columns: 1fr auto 1fr;

			& > .item-content {
				grid-column: 2/3;
			}

			& > .item-content__icon-container-wrapper {
				grid-column: 3/4;
			}
		}

		&--align-right {
			grid-template-columns: 1fr auto;

			& > .item-content {
				grid-column: 3/4;
			}

			& > .item-content__icon-container-wrapper {
				grid-row: 1;
				grid-column: 2/3;
				margin-right: 8px;
				margin-left: 0;
			}
		}
	}
}
</style>
