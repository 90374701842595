<template>
	<ZyroButton
		v-qa="`builder-header-btn-switchview${isMobileView ? '-mobile' : ''}`"
		class="screen-toggle"
		:class="{ 'is-mobile': isMobileView }"
		theme="header"
		color="white"
		icon="desktop-mobile"
		:title="$t('common.toggleMobile')"
		@click="toggleMobileView"
	/>
</template>

<script>
import {
	mapState,
	mapActions,
} from 'vuex';

export default {
	computed: mapState('gui', ['isMobileView']),
	methods: mapActions('gui', ['toggleMobileView']),
};
</script>

<style scoped lang="scss">
.screen-toggle {
	&:not(.is-mobile) {
		color: $dark;
		fill: $secondary;

		&:hover,
		&:focus {
			color: $accent-two;
		}
	}

	&.is-mobile {
		color: $secondary;
		fill: $dark;

		&:hover,
		&:focus {
			fill: $accent-two;
		}
	}
}
</style>
