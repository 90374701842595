<template>
	<button>
		<svg
			width="8"
			height="14"
			viewBox="0 0 8 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 1L0.999999 7L7 13"
				stroke="#EBEFF2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</button>
</template>

<script>
export default { name: 'LightboxNavButton' };
</script>
