import { render, staticRenderFns } from "./BlockNavigationItem.vue?vue&type=template&id=5aa6b50a&scoped=true&"
import script from "./BlockNavigationItem.vue?vue&type=script&lang=js&"
export * from "./BlockNavigationItem.vue?vue&type=script&lang=js&"
import style0 from "./BlockNavigationItem.vue?vue&type=style&index=0&id=5aa6b50a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa6b50a",
  null
  
)

export default component.exports