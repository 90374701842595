var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation",class:{
		'navigation--no-logo': !_vm.showLogo,
		'navigation--sticky': _vm.isSticky,
		'navigation--open' : _vm.isOpen
	},style:(_vm.computedStyles)},[(_vm.showLogo)?_c('div',{staticClass:"navigation__logo",class:{ 'navigation__logo--m-with-cart': _vm.isCartVisible },on:{"click":_vm.handleLogoClick}},[_c('img',{staticClass:"navigation__logo-img",attrs:{"src":_vm.logoUrl,"alt":"Logo"}})]):_vm._e(),(_vm.itemsIds.length)?_c('ZyroHamburger',{staticClass:"navigation__burger",class:{ 'navigation__burger--m-with-cart': _vm.isCartVisible },attrs:{"is-open":_vm.isOpen},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('ul',{staticClass:"navigation__links",class:{ 'navigation__links--open': _vm.isOpen }},[_vm._l((_vm.navigationItems),function(item){return [_c('BlockNavigationItem',{key:item.id,attrs:{"item":item,"navigation-placement":_vm.isMobileScreen ? _vm.navigationPlacementMobile : _vm.navigationPlacement,"is-mobile-view":_vm.isMobileScreen,"navigation-background":_vm.data.background},scopedSlots:_vm._u([{key:"link",fn:function(ref){
	var navigationItem = ref.navigationItem;
	var classes = ref.classes;
	var toggleDropdown = ref.toggleDropdown;
return [(navigationItem.isPage || navigationItem.isInternalLink)?_c('RouterLink',{class:classes,attrs:{"to":navigationItem.to},domProps:{"textContent":_vm._s(navigationItem.name)},nativeOn:{"click":function($event){return _vm.handleEcwidHome(item.id)}}}):_c('a',{class:classes,attrs:{"target":navigationItem.target,"href":navigationItem.url},domProps:{"textContent":_vm._s(navigationItem.name)},on:{"click":function($event){return _vm.handleNavigationItemClick({
							navigationItem: navigationItem,
							event: $event,
							toggleDropdown: toggleDropdown,
						})}}})]}}],null,true)})]})],2),_c('BlockNavigationCartUser',{staticClass:"navigation__cart",attrs:{"is-cart-visible":_vm.isCartVisible,"cart-text":_vm.cart.cartText,"cart-icon-size":_vm.cart.cartIconSize,"items-in-cart":_vm.ecwidCartItemCount},on:{"click":_vm.handleCartPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }