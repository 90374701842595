export const logoMenuPositionMap = {
	// LogoPosition-NavigationPosition
	'left-left': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '1/2',
		menuColumn: '2/3',
		cartColumn: '3/3',
		templateColumns: 'minmax(calc(var(--logo-width) + var(--space-between-menu)), auto) 1fr',
		mTemplateColumns: 'minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto) 1fr',
	},
	'left-right': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '1/2',
		menuColumn: '2/3',
		cartColumn: '3/3',
		templateColumns: 'minmax(calc(var(--logo-width) + var(--space-between-menu)), auto) 1fr',
		mTemplateColumns: 'minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto) 1fr',
	},
	'left-center': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '1/2',
		menuColumn: '2/3',
		cartColumn: '3/3',
		templateColumns: 'minmax(calc(var(--logo-width) + var(--space-between-menu)), 1fr) auto 1fr',
		mTemplateColumns: 'minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), 1fr) auto 1fr',
	},
	'center-left': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '2/3',
		menuColumn: '1/2',
		cartColumn: '3/4',
		templateColumns: '1fr minmax(calc(var(--logo-width) + var(--space-between-menu)), auto) 1fr',
		mTemplateColumns: '1fr minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto) 1fr',
	},
	'center-right': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '2/3',
		menuColumn: '3/4',
		cartColumn: '4/4',
		templateColumns: '1fr minmax(calc(var(--logo-width) + var(--space-between-menu)), auto) 1fr',
		mTemplateColumns: '1fr minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto) 1fr',
	},
	'center-center': {
		logoRow: 0,
		menuRow: 2,
		cartRow: 1,
		logoColumn: '2/3',
		menuColumn: '1/4',
		cartColumn: '3/3',
		templateColumns: '1fr minmax(var(--logo-width), auto) 1fr',
		mTemplateColumns: '1fr minmax((--m-logo-width, var(--logo-width)), auto) 1fr',
	},
	'right-left': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '2/3',
		menuColumn: '1/2',
		cartColumn: '3/3',
		templateColumns: '1fr minmax(calc(var(--logo-width) + var(--space-between-menu)), auto)',
		mTemplateColumns: '1fr minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto)',
	},
	'right-right': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '2/3',
		menuColumn: '1/2',
		cartColumn: '3/3',
		templateColumns: '1fr minmax(calc(var(--logo-width) + var(--space-between-menu)), auto)',
		mTemplateColumns: '1fr minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), auto)',
	},
	'right-center': {
		logoRow: 1,
		menuRow: 1,
		cartRow: 1,
		logoColumn: '3/4',
		menuColumn: '2/3',
		cartColumn: '4/4',
		templateColumns: '1fr auto minmax(calc(var(--logo-width) + var(--space-between-menu)), 1fr)',
		mTemplateColumns: '1fr auto minmax(calc(var(--m-logo-width, var(--logo-width)) + var(--space-between-menu)), 1fr)',
	},
};
