<template>
	<nav
		class="navigation"
		:style="computedStyles"
		:class="{
			'navigation--no-logo': !showLogo,
			'navigation--sticky': isSticky,
			'navigation--open' : isOpen
		}"
	>
		<div
			v-if="showLogo"
			class="navigation__logo"
			:class="{ 'navigation__logo--m-with-cart': isCartVisible }"
			@click="handleLogoClick"
		>
			<img
				class="navigation__logo-img"
				:src="logoUrl"
				alt="Logo"
			>
		</div>

		<ZyroHamburger
			v-if="itemsIds.length"
			class="navigation__burger"
			:class="{ 'navigation__burger--m-with-cart': isCartVisible }"
			:is-open="isOpen"
			@click="isOpen = !isOpen"
		/>
		<ul
			class="navigation__links"
			:class="{ 'navigation__links--open': isOpen }"
		>
			<template v-for="item in navigationItems">
				<BlockNavigationItem
					:key="item.id"
					:item="item"
					:navigation-placement="isMobileScreen ? navigationPlacementMobile : navigationPlacement"
					:is-mobile-view="isMobileScreen"
					:navigation-background="data.background"
				>
					<template #link="{ navigationItem, classes, toggleDropdown }">
						<RouterLink
							v-if="navigationItem.isPage || navigationItem.isInternalLink"
							:to="navigationItem.to"
							:class="classes"
							@click.native="handleEcwidHome(item.id)"
							v-text="navigationItem.name"
						/>
						<a
							v-else
							:target="navigationItem.target"
							:class="classes"
							:href="navigationItem.url"
							@click="handleNavigationItemClick({
								navigationItem,
								event: $event,
								toggleDropdown,
							})"
							v-text="navigationItem.name"
						/>
					</template>
				</BlockNavigationItem>
			</template>
		</ul>
		<BlockNavigationCartUser
			class="navigation__cart"
			:is-cart-visible="isCartVisible"
			:cart-text="cart.cartText"
			:cart-icon-size="cart.cartIconSize"
			:items-in-cart="ecwidCartItemCount"
			@click="handleCartPageChange"
		/>
	</nav>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import BlockNavigationItem from '@/components/block-navigation/block-navigation-item/BlockNavigationItem.vue';
import { logoMenuPositionMap } from '@/components/block-navigation/logoMenuPositionMap';
import ZyroHamburger from '@/components/ui/ZyroHamburger.vue';
import {
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_LINK_TYPE,
	NAVIGATION_FOLDER_TYPE,
	NAVIGATION_LOGO_SPACING_MAP,
} from '@/use/navigation/userConstants';
import BlockNavigationCartUser from '@user/components/block-navigation/BlockNavigationCartUser.vue';
import { HOME_ROUTE_NAME } from '@user/constantsUser';
import { useEcwidStore } from '@user/useStore/useEcwidStore';
import { scrollToAnchorTag } from '@user/utils/anchorTag';

export default {
	NAVIGATION_PAGE_TYPE,
	components: {
		BlockNavigationCartUser,
		ZyroHamburger,
		BlockNavigationItem,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const {
			ecwidCartItemCount,
			openCart,
		} = useEcwidStore();

		return {
			ecwidCartItemCount,
			openCart,
		};
	},
	data() {
		return { isOpen: false };
	},
	computed: {
		...mapState(['website']),
		...mapGetters('pages', [
			'defaultPages',
			'ecommercePages',
		]),
		...mapGetters(['hasEcwid']),
		...mapGetters('navigation', [
			'items',
			'itemsIds',
		]),
		...mapState('gui', [
			'isMobileScreen',
			'isTemplatePreviewMode',
		]),
		setup() {
			const { ecwidCartItemCount } = useEcwidStore();

			return { ecwidCartItemCount };
		},
		cart: ({ data }) => ({
			isCartVisible: data.settings.isCartVisible,
			cartText: data.settings.cartText,
			cartIconSize: data.settings.styles.cartIconSize,
		}),
		navigationItems() {
			const mapItem = (itemId) => {
				const item = this.items[itemId];
				const {
					name,
					type,
					target,
					url,
				} = item;
				const isPage = type === NAVIGATION_PAGE_TYPE;
				const isLink = type === NAVIGATION_LINK_TYPE;
				const isFolder = type === NAVIGATION_FOLDER_TYPE;
				const internalLink = this.website.pages[url]?.path;
				const path = internalLink || this.defaultPages[itemId]?.path;

				return {
					...item,
					name: isPage ? this.defaultPages[itemId].name : name,
					to: path,
					id: itemId,
					isPage,
					isLink,
					isFolder,
					isInternalLink: !!internalLink,
					hasDropdown: !!item.subItems.length || item.isFolder,
					target: this.isTemplatePreviewMode ? '_blank' : target,
					isCurrent: this.$route.path === path,
					subItems: item.subItems.map((subItemId) => mapItem(subItemId)),
				};
			};

			return this.itemsIds.map(mapItem);
		},
		isCartVisible() {
			return this.cart.isCartVisible && this.hasEcwid;
		},
		logoUrl() {
			return this.data.settings.logoUrl;
		},
		showLogo() {
			return this.data.settings.showLogo;
		},
		isSticky() {
			return this.data.settings.isSticky;
		},
		isTransparent() {
			return this.data.background.isTransparent;
		},
		logoPlacement() {
			return this.data.settings.logoPlacement;
		},
		logoPlacementMobile() {
			return this.data.settings['m-logoPlacement'] || this.data.settings.logoPlacement;
		},
		navigationPlacement() {
			return this.data.settings.navigationPlacement;
		},
		navigationPlacementMobile() {
			return this.data.settings['m-navigationPlacement'] || this.data.settings.navigationPlacement;
		},
		isLogoAndNavigationCentered() {
			return this.logoPlacement === 'center' && this.navigationPlacement === 'center';
		},
		navigationGridRowCount() {
			if (this.showLogo && this.isCartVisible) {
				return 3;
			}

			return 2;
		},
		computedStyles() {
			const placementKey = `${this.logoPlacement}-${this.navigationPlacement}`;
			const placementKeyMobile = `${this.logoPlacementMobile}-${this.navigationPlacementMobile}`;

			const { templateColumns } = logoMenuPositionMap[placementKey];
			const templateColumnsMobile = this.isCartVisible
				? 'auto minmax(0, calc(var(--m-logo-width) + var(--space-between-menu) * 2)) 1fr'
				: logoMenuPositionMap[placementKeyMobile].mTemplateColumns;

			const hamburgerMenuPositionTop = !this.isCartVisible && placementKeyMobile !== 'center-center'
				? 'calc(var(--m-padding-top, var(--padding-top)) - 1px)'
				: 'calc(100% + var(--m-padding-top, var(--padding-top)))';

			return {
				'--navigation-grid-template-columns': this.showLogo ? templateColumns : '1fr',
				'--m-navigation-grid-template-columns': this.showLogo ? templateColumnsMobile : '1fr',

				'--logo-grid-row': `${logoMenuPositionMap[placementKey].logoRow}/${this.navigationGridRowCount}`,
				'--m-logo-grid-row': `${logoMenuPositionMap[placementKeyMobile].logoRow}/${this.navigationGridRowCount}`,

				'--logo-grid-column': logoMenuPositionMap[placementKey].logoColumn,
				'--m-logo-grid-column': logoMenuPositionMap[placementKeyMobile].logoColumn,

				'--logo-justify-self': this.getJustifyContent(this.logoPlacement),
				'--m-logo-justify-self': this.getJustifyContent(this.logoPlacementMobile),

				'--logo-image-object-position': this.getLogoImageObjectPosition(this.logoPlacement),
				'--m-logo-image-object-position': this.getLogoImageObjectPosition(this.logoPlacementMobile),

				'--links-grid-row': `${logoMenuPositionMap[placementKey].menuRow}/${this.navigationGridRowCount}`,
				'--m-links-grid-row': `${logoMenuPositionMap[placementKeyMobile].menuRow}/${this.navigationGridRowCount}`,

				'--links-grid-column': this.showLogo ? logoMenuPositionMap[placementKey].menuColumn : '1/2',
				'--m-links-grid-column': this.showLogo ? logoMenuPositionMap[placementKeyMobile].menuColumn : '1/2',

				'--cart-grid-row': `${logoMenuPositionMap[placementKey].cartRow}/${this.navigationGridRowCount}`,
				'--cart-grid-column': this.showLogo ? logoMenuPositionMap[placementKey].cartColumn : '2/2',

				'--logo-spacing': this.getLogoMarginPlacement(),
				'--m-logo-spacing': this.getLogoMarginPlacement(true),

				'--navigation-text-align': this.navigationPlacement,
				'--m-navigation-text-align': this.navigationPlacementMobile,

				'--navigation-justify-self': this.getJustifyContent(this.navigationPlacement),
				'--m-navigation-justify-self': this.getJustifyContent(this.navigationPlacementMobile),

				'--hamburgerMenuPositionTop': hamburgerMenuPositionTop,
			};
		},
	},
	watch: {
		$route() {
			this.isOpen = false;
		},
	},
	methods: {
		navigationItemTarget(itemId) {
			return this.isTemplatePreviewMode ? '_blank' : this.items[itemId].target;
		},
		handleLogoClick() {
			if (this.$route.name !== HOME_ROUTE_NAME) {
				this.$router.push({ name: HOME_ROUTE_NAME });
			}
		},
		handleEcwidHome(pageId) {
			const isSelectedPageWithEcwid = Object.keys(this.ecommercePages).includes(pageId);

			if (window.Ecwid && isSelectedPageWithEcwid) {
				window.Ecwid.openPage('category');
			}
		},
		handleCartPageChange() {
			const isCurrentPageEcommerce = Object.values(this.ecommercePages).some(({ path }) => path === this.$route.path);

			if (!isCurrentPageEcommerce && this.$route.query?.['store-page'] !== 'cart') {
				this.goToFirstEcommercePageWithCart();
			} else if (isCurrentPageEcommerce) {
				this.openCart();
			}
		},
		goToFirstEcommercePageWithCart() {
			const firstEcommercePagePath = Object.values(this.ecommercePages)[0].path;

			this.$router.push({
				path: firstEcommercePagePath,
				query: { 'store-page': 'cart' },
			});
		},
		handleNavigationItemClick({
			navigationItem,
			event,
			toggleDropdown,
		}) {
			if (navigationItem.isLink) {
				scrollToAnchorTag(navigationItem.url, event);
			} else if (navigationItem.isFolder && this.isMobileScreen) {
				toggleDropdown();
			}
		},
		getJustifyContent(align) {
			switch (align) {
			case 'left': return 'flex-start';
			case 'right': return 'flex-end';
			case 'center': return 'center';
			default: return 'center';
			}
		},
		getLogoImageObjectPosition(align) {
			switch (align) {
			case 'left': return 'left center';
			case 'right': return 'right center';
			case 'center': return 'center center';
			default: return 'left center';
			}
		},
		getLogoMarginPlacement(isMobile = false) {
			const isLogoAndNavigationCentered = isMobile ? this.isLogoAndNavigationCenteredMobile : this.isLogoAndNavigationCentered;
			const logoPlacement = isMobile ? this.logoPlacementMobile : this.logoPlacement;

			if (isMobile && this.isCartVisible) {
				return NAVIGATION_LOGO_SPACING_MAP.center;
			}

			if (isLogoAndNavigationCentered) {
				return NAVIGATION_LOGO_SPACING_MAP['center-center'];
			}

			return NAVIGATION_LOGO_SPACING_MAP[logoPlacement];
		},
	},
};
</script>
<style lang="scss" scoped>
@import './BlockNavigation.scss';
</style>
