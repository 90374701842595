<template>
	<header
		ref="headerRef"
		class="header"
		:class="{ 'header--with-shadow': data.settings.isSticky && hasUserScrolled }"
		:style="variablesString"
	>
		<BlockBackground :background="background" />
		<Component
			:is="navigationComponent"
			:data="data"
		/>
	</header>
</template>

<script>
import {
	mapMutations,
	mapState,
} from 'vuex';

import BlockNavigation from '@/components/block-navigation/BlockNavigation.vue';
import { useInlineCSSVariables } from '@/use/useInlineCSSVariables';
import BlockNavigationUser from '@user/components/block-navigation/BlockNavigationUser.vue';
import BlockBackground from '@user/components/block/BlockBackground.vue';

export default {
	name: 'BlockHeader',
	components: {
		BlockNavigation,
		BlockNavigationUser,
		BlockBackground,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		hasUserScrolled: {
			type: Boolean,
			default: false,
		},
		pageBlocks: {
			type: Array,
			default: () => ([]),
		},
		// is nav using a router or setCurentPage (preview) for navigation
		isPreviewNavigation: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const { variablesString } = useInlineCSSVariables(props);

		return { variablesString };
	},
	data() {
		return { navResizeObserver: null };
	},
	computed: {
		...mapState('navigation', ['navigationHeight']),
		navigationComponent: ({ isPreviewNavigation }) => (isPreviewNavigation ? 'BlockNavigation' : 'BlockNavigationUser'),
		background({ data }) {
			const forceTransparency = data.settings.isSticky
			&& data.background?.isTransparent
			&& !this.hasUserScrolled;

			return forceTransparency ? {
				current: 'color',
				color: 'transparent',
			} : data?.background;
		},
	},
	mounted() {
		// idk why this was added in user component on mount, before observer
		this.$forceUpdate();

		this.navResizeObserver = new ResizeObserver(([{ contentRect }]) => {
			const { height } = contentRect;

			if (height !== this.navigationHeight) {
				this.updateNavigationHeight(height);
			}
		});

		this.navResizeObserver.observe(this.$refs.headerRef);
	},
	beforeDestroy() {
		this.navResizeObserver.disconnect();
	},
	methods: { ...mapMutations('navigation', ['updateNavigationHeight']) },
};
</script>

<style lang="scss">
.header {
	position: sticky;
	z-index: z-index(user--header);
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	width: 100%;
	transition: box-shadow 0.15s linear;

	&--with-shadow {
		box-shadow: 0 0 14px rgba(0, 0, 0, 0.1); // implement this in header, I guess
	}
}
</style>
