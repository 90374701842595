export const NAVIGATION_LINK_TYPE = 'Link';
export const NAVIGATION_PAGE_TYPE = 'Page';
export const NAVIGATION_FOLDER_TYPE = 'Folder';
export const NAVIGATION_HOMEPAGE_TYPE = 'Homepage';

export const NAVIGATION_GROUP_ROOT = 'ROOT';
export const NAVIGATION_GROUP_HIDDEN = 'HIDDEN';

export const NAVIGATION_LOGO_SPACING_MAP = {
	center: '0 var(--space-between-menu)',
	left: '0 var(--space-between-menu) 0 0',
	right: '0 0 0 var(--space-between-menu)',
	'center-center': '0 0 var(--space-between-menu) 0',
};
